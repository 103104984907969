import React, { useState, useEffect } from 'react';
import { useTranslation, useEnvironment, Trans } from '@wix/yoshi-flow-editor';
import { bookingsUoUMemberAreaCancellationFeeModalSrc16Evid588 } from '@wix/bi-logger-bookings-data/v2';
import { useBiLogger } from '../../../../bi/biLoggerFactory';
import {
  CancellationFeeModalComponents,
  CancellationFeeModalActions,
  CancellationFeeModalElements,
} from '../../../../../types/Bi';
import { isRescheduleAble } from '../../../../../utils/bookInfo';
import {
  ActionButtonUiTypes,
  BookingsGroup,
  CloseModalTriggers,
  RescheduleButtonOrigin,
} from '../../../../../types';
import { CancellationModalDataHooks } from '../../../../../components/MyBookings/Widget/datahooks';
import { Modal } from '../../../../../components/MyBookings/Components/Modal';
import { useApplicationContext } from '../../../../hooks/useApplicationContext';
import Link from '../../../../../components/MyBookings/Components/Link';
import { Reschedule } from '../../../../../components/MyBookings/Widget/ActionsButtons/Reschedule';
import { classes } from '../CancellationModal.st.css';
import { BookingPolicyModalContent } from './PolicyModal';
import { FeeInfo } from '../../../../../types/CancellationFeeInfo';

export interface CancellationFeeModalContentProps {
  group: BookingsGroup;
  feeInfo: FeeInfo;
}

export const CancellationFeeModalContent: React.FC<
  CancellationFeeModalContentProps
> = ({ group, feeInfo }) => {
  const state = useApplicationContext();
  const [isBookingPolicyModalOpen, setIsBookingPolicyModalOpen] =
    useState<boolean>(false);
  const { actions } = useApplicationContext();
  const { t } = useTranslation();
  const biLogger = useBiLogger(state);
  const { isMobile } = useEnvironment();
  const totalFeeToDisplay = feeInfo.totalFee;
  const policies = feeInfo.policies;

  useEffect(() => {
    biLogger?.report(
      bookingsUoUMemberAreaCancellationFeeModalSrc16Evid588({
        element: CancellationFeeModalElements.CANCELLATION_FEE_UOU,
        component: CancellationFeeModalComponents.MODAL,
        action: CancellationFeeModalActions.load,
      }),
    );
  }, []);

  const handleCancelButtonClick = () => {
    actions.clickOnCancel({
      group,
    });
    actions.closeModal();
  };

  const handleCloseButtonClick = () => {
    actions.closeModal(CloseModalTriggers.CloseModal);
  };

  const onBookingPolicyOpen = () => {
    setIsBookingPolicyModalOpen(true);
  };

  const onBookingPolicyClose = () => {
    setIsBookingPolicyModalOpen(false);
  };

  const renderCancellationFeeModalContent = () => (
    <Modal
      dataHook={CancellationModalDataHooks.CancellationFee}
      isLoading={!totalFeeToDisplay}
      modalTitle={t('app.my-bookings-widget.cancellation-fee-modal.title')}
      modalSubtitle={
        <Trans
          i18nKey="app.my-bookings-widget.cancellation-fee-modal.content"
          values={{
            amount: totalFeeToDisplay?.formattedValue,
          }}
        >
          <Link
            data-hook={
              CancellationModalDataHooks.ShowBookingPolicyLinkFromCancelFee
            }
            onClick={onBookingPolicyOpen}
          />
        </Trans>
      }
      primaryButton={{
        context: {
          label: t(
            'app.my-bookings-widget.cancellation-fee-modal.primary-button.label',
          ),
          onClick: handleCancelButtonClick,
        },
      }}
      secondaryButton={{
        context: {
          label: t(
            'app.my-bookings-widget.cancellation-fee-modal.secondary-button.label',
          ),
          onClick: handleCloseButtonClick,
        },
      }}
    />
  );

  const renderCancellationFeeModalWithRescheduleContent = () => (
    <Modal
      dataHook={CancellationModalDataHooks.CancellationFeeWithReschedule}
      isLoading={!totalFeeToDisplay}
      modalTitle={t(
        'app.my-bookings-widget.cancellation-fee-reschedule-modal.title',
      )}
      modalSubtitle={
        <Trans
          i18nKey="app.my-bookings-widget.cancellation-fee-reschedule-modal.content"
          values={{
            amount: totalFeeToDisplay?.formattedValue,
          }}
        >
          <Link
            data-hook={
              CancellationModalDataHooks.ShowBookingPolicyLinkFromRescheduleCancelFee
            }
            onClick={onBookingPolicyOpen}
          />
        </Trans>
      }
      primaryButton={{
        context: {
          label: t(
            'app.my-bookings-widget.cancellation-fee-reschedule-modal.primary-button.label',
          ),
          onClick: handleCancelButtonClick,
        },
      }}
      secondaryButton={{
        component: (
          <Reschedule
            origin={RescheduleButtonOrigin.CancellationFeeModal}
            label={t(
              'app.my-bookings-widget.cancellation-fee-reschedule-modal.secondary-button.label',
            )}
            group={group}
            fullWidthButton={isMobile}
            type={ActionButtonUiTypes.BUTTON}
            className={classes.rescheduleButton}
          />
        ),
      }}
    />
  );

  const renderModalContent = () => {
    if (isBookingPolicyModalOpen && policies) {
      return (
        <BookingPolicyModalContent
          policies={policies}
          onClose={onBookingPolicyClose}
        />
      );
    } else if (isRescheduleAble(group)) {
      return renderCancellationFeeModalWithRescheduleContent();
    } else {
      return renderCancellationFeeModalContent();
    }
  };

  return renderModalContent();
};
