import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import React, { useEffect } from 'react';
import { isCourse, isRescheduleAble } from '../../../../utils/bookInfo';
import {
  ActionButtonUiTypes,
  BookingsGroup,
  CloseModalTriggers,
  RescheduleButtonOrigin,
} from '../../../../types';
import { useGroupHeaderGetters } from '../../../hooks/useGroupHeaderGetter';
import { CancellationModalDataHooks } from '../../../../components/MyBookings/Widget/datahooks';
import { Modal } from '../../../../components/MyBookings/Components/Modal';
import { useApplicationContext } from '../../../hooks/useApplicationContext';
import { Reschedule } from '../../../../components/MyBookings/Widget/ActionsButtons/Reschedule';
import { ModalFacadeProps } from '../..';
import { classes } from './CancellationModal.st.css';
import { CancellationFeeModalContent } from './CancellationFeeModalsContent';
import { ExperimentsConsts } from '../../../../consts/experiments';

export interface CancellationModalProps {
  group: BookingsGroup;
}

export const CancellationModal: React.FC<
  CancellationModalProps & ModalFacadeProps
> = ({ group }) => {
  const { actions, cancellationFeeInfo } = useApplicationContext();
  const { t } = useTranslation();
  const { getTitle } = useGroupHeaderGetters();
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();
  const groupFeeId = group.groupId || group.bookings[0]?.bookingId;
  const feeInfo = cancellationFeeInfo?.[groupFeeId];

  const isMyBookingsShowCourseCancellationModalEnabled = experiments.enabled(
    ExperimentsConsts.MyBookingsShowCourseCancellationModal,
  );
  const isCancellationFeesUoU = experiments.enabled(
    ExperimentsConsts.CancellationFeesUoU,
  );

  useEffect(() => {
    if (!feeInfo && isCancellationFeesUoU) {
      actions.getCancellationFeeInfo({
        group,
      });
    }
  }, []);

  const handlePrimaryButtonClick = () => {
    actions.clickOnCancel({
      group,
    });
    actions.closeModal();
  };

  const handleSecondaryButtonClick = () => {
    actions.closeModal(CloseModalTriggers.CloseModal);
  };

  const renderCancellationModalWithRescheduleContent = () => (
    <Modal
      dataHook={CancellationModalDataHooks.Reschedule}
      modalTitle={t(
        'app.my-bookings-widget.cancellation-modal-with-reschedule.title',
      )}
      modalSubtitle={t(
        'app.my-bookings-widget.cancellation-modal-with-reschedule.content',
      )}
      primaryButton={{
        context: {
          label: t(
            'app.my-bookings-widget.cancellation-modal-with-reschedule.primary-button.label',
          ),
          onClick: handlePrimaryButtonClick,
        },
      }}
      secondaryButton={{
        component: (
          <Reschedule
            origin={RescheduleButtonOrigin.CancellationModal}
            group={group}
            fullWidthButton={isMobile}
            type={ActionButtonUiTypes.BUTTON}
            className={classes.rescheduleButton}
          />
        ),
      }}
    />
  );

  const renderCancellationModalContent = () => (
    <Modal
      dataHook={CancellationModalDataHooks.Cancellation}
      modalTitle={t('app.my-bookings-widget.cancellation-modal.title')}
      modalSubtitle={t('app.my-bookings-widget.cancellation-modal.content', {
        serviceName: getTitle(group),
      })}
      primaryButton={{
        context: {
          label: t(
            'app.my-bookings-widget.cancellation-modal.primary-button.label',
          ),
          onClick: handlePrimaryButtonClick,
        },
      }}
      secondaryButton={{
        context: {
          label: t(
            'app.my-bookings-widget.cancellation-modal.secondary-button.label',
          ),
          onClick: handleSecondaryButtonClick,
        },
      }}
    />
  );

  const renderGroupCancellationModalContent = () => (
    <Modal
      dataHook={CancellationModalDataHooks.GroupCancellation}
      modalTitle={t('app.my-bookings-widget.group-cancellation-modal.title')}
      modalSubtitle={t(
        'app.my-bookings-widget.group-cancellation-modal.content',
      )}
      primaryButton={{
        context: {
          label: t(
            'app.my-bookings-widget.group-cancellation-modal.primary-button.label',
          ),
          onClick: handlePrimaryButtonClick,
        },
      }}
      secondaryButton={{
        context: {
          label: t(
            'app.my-bookings-widget.group-cancellation-modal.secondary-button.label',
          ),
          onClick: handleSecondaryButtonClick,
        },
      }}
    />
  );

  const renderCourseCancellationModalContent = () => (
    <Modal
      dataHook={CancellationModalDataHooks.CourseCancellation}
      modalTitle={t('app.my-bookings-widget.course-cancellation-modal.title')}
      modalSubtitle={t(
        'app.my-bookings-widget.course-cancellation-modal.content',
        { courseName: getTitle(group) },
      )}
      primaryButton={{
        context: {
          label: t(
            'app.my-bookings-widget.course-cancellation-modal.primary-button.label',
          ),
          onClick: handlePrimaryButtonClick,
        },
      }}
      secondaryButton={{
        context: {
          label: t(
            'app.my-bookings-widget.course-cancellation-modal.secondary-button.label',
          ),
          onClick: handleSecondaryButtonClick,
        },
      }}
    />
  );

  const renderLoaderModalContent = () => (
    <Modal
      isLoading={true}
      dataHook={CancellationModalDataHooks.CancellationLoaderModal}
      modalTitle={t('app.my-bookings-widget.course-cancellation-modal.title')}
    />
  );

  const renderModalContent = () => {
    if (!feeInfo && isCancellationFeesUoU) {
      return renderLoaderModalContent();
    } else if (feeInfo?.totalFee?.value) {
      return <CancellationFeeModalContent group={group} feeInfo={feeInfo} />;
    } else if (isRescheduleAble(group)) {
      return renderCancellationModalWithRescheduleContent();
    } else if (group.bookings.length > 1) {
      return renderGroupCancellationModalContent();
    } else if (
      isMyBookingsShowCourseCancellationModalEnabled &&
      isCourse(group)
    ) {
      return renderCourseCancellationModalContent();
    } else {
      return renderCancellationModalContent();
    }
  };

  return (
    <div data-hook={CancellationModalDataHooks.Main}>
      {renderModalContent()}
    </div>
  );
};
